import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        title:
          'Be the first to know when, where & how to catch Burna Boy in France!',
        FirstName: 'First Name',
        LastName: 'Last Name',
        MobileNumber: 'Mobile Number',
        Email: 'Email',
        Country: 'Country',
        SignUp: 'Sign Up',
        FirstNameRequired: 'First name is required.',
        LastNameRequired: 'Last name is required.',
        EmailRequired: 'Email is required.',
        CountryRequired: 'Kindly select your country.',
        SelectCountry: 'Select Country',
        FirstNameAlphabets: 'The First name must contain only alphabets.',
        LastNameAlphabets: 'The Last name must contain only alphabets.',
        PhoneNumberInvalid: 'Phone number is not valid.',
        EmailInvalid: 'Enter valid email.',
        BennerTitle:
          'WE WILL DROP THE FRANCE DATE FOR BURNA BOY WHEN WE GET 100,000 SIGN UPS!',
        RegistrationComplete: 'Registration Complete',
        ThankYouForRegistering:
          'Thank you for registering your interest. We will notify you about this event.',
        SignUp: 'Sign up',
        RegisteredSuccessfully: 'Registered Successfully',
      },
    },
    fr: {
      translation: {
        title: 'Inscrivez-vous ci-dessous et soyez le premier informé!',
        FirstName: 'Prénom',
        LastName: 'Nom de famille',
        MobileNumber: 'Numéro de portable',
        Email: 'E-mail',
        Country: 'Pays',
        SignUp: "S'inscrire",
        FirstNameRequired: 'Le prénom est requis.',
        LastNameRequired: 'Le nom de famille est requis.',
        EmailRequired: 'Email est requis.',
        CountryRequired: 'Veuillez sélectionner votre pays.',
        SelectCountry: 'Choisissez le pays',
        FirstNameAlphabets: 'Le prénom doit contenir uniquement des alphabets.',
        LastNameAlphabets:
          'Le nom de famille doit contenir uniquement des alphabets.',
        PhoneNumberInvalid: "Le numéro de téléphone n'est pas valide.",
        EmailInvalid: 'Entrez un email valide.',
        BennerTitle:
          'NOUS ABANDONNONS LA DATE EN FRANCE POUR BURNA BOY LORSQUE NOUS OBTENONS 100 000 INSCRIPTIONS !',
        RegistrationComplete: 'Inscription complète',
        ThankYouForRegistering:
          "Merci d'avoir manifesté votre intérêt. Nous vous informerons de cet événement.",
        SignUp: `S'inscrire`,
        RegisteredSuccessfully: 'enregistré avec succès',
      },
    },
  },
  lng: 'fr', // default language
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
