export const TICKETSIR_AUTH_USER = 'TICKETSIR_AUTH_USER';

export const TICKETSIR_AUTH_USER_TOKEN = 'TICKETSIR_AUTH_USER_TOKEN';

export const TS_HOST_CURRENT_EVENT_ID = 'TS_HOST_CURRENT_EVENT_ID';

export const TS_HOST_CURRENT_EVENT_HOST_ID = 'TS_HOST_CURRENT_EVENT_HOST_ID';

export const TS_HOST_CURRENT_EVENT_COMPLETED_STEPS =
  'TS_HOST_CURRENT_EVENT_COMPLETED_STEPS';

export const EXPIRED_JWT_ERROR_MESSAGE = 'Could not verify JWT: JWTExpired';

export const TS_BOOKING_SESSION_AND_EXPIRATION =
  'TS_BOOKING_SESSION_AND_EXPIRATION';

export const FURP_AUTH = 'FURP_AUTH';

export const TS_ACCEPT_COOKIES = 'TS_ACCEPT_COOKIES';

export const TS_AUTH_USER_REST_TOKEN = 'TS_AUTH_USER_REST_TOKEN';

export const TS_HOST_CURRENT_IS_TEMPLATE = 'TS_HOST_CURRENT_IS_TEMPLATE';

